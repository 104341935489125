<template>
  <div class="enterprise">
    <div class="top_banner">
      <div class="img01">
        <h1>企业准入方案</h1>
        <p>适用于园区企业、科技、互联网中大型企业</p>
        <button type="button" @click="trial">申请试用</button>
      </div>
    </div>

    <div class="enterprise_context">
      <div class="point">
        <h2 class="enterprise_h2">面临痛点</h2>
        <div class="any">
          <div class="anyitem">
            <img src="@/assets/img/pc/enterprise/anyitem01.png" />
            <p>统一密码上网信息容易泄露</p>
          </div>
          <div class="anyitem">
            <img src="@/assets/img/pc/enterprise/anyitem02.png" />
            <p>员工访客无法隔离开</p>
          </div>
          <div class="anyitem">
            <img src="@/assets/img/pc/enterprise/anyitem03.png" />
            <p>终端及厂商类型众多，不易管理</p>
          </div>
          <div class="anyitem">
            <img src="@/assets/img/pc/enterprise/anyitem04.png" />
            <p>上网无法实名制，不合审计要求</p>
          </div>
        </div>
      </div>

      <div class="detailed">
        <h2 class="enterprise_h2">方案详细设计</h2>
        <div class="pro_detailed odd">
          <div class="left">
            <h3>员工、访客统一接入认证</h3>
            <p>
              区分员工、访客，满足不同角色的有线无线上网认证需求，针对不同的用户身份，可设置不同的有线无线上网权限。并且支持多分支有线无线统一认证、授权、审计和无缝漫游。
            </p>
            <button type="button" @click="trial">申请试用</button>
          </div>
          <div class="right">
            <img src="@/assets/img/pc/enterprise/pro_detailed01.png" />
          </div>
        </div>
        <div class="pro_detailed even">
          <div class="left">
            <img src="@/assets/img/pc/enterprise/pro_detailed02.png" />
          </div>
          <div class="right">
            <h3>个性化定制页面</h3>
            <p>
              登录页面支持个性化定制，可加入企业logo、页面样式、广告推送等，助力品牌推广战略。终端自适应，中英文双语，供国内外平台使用。
            </p>
            <button type="button">申请试用</button>
          </div>
        </div>
        <div class="pro_detailed odd">
          <div class="left">
            <h3>兼容主流厂商/品牌网络设备</h3>
            <p>
              有线无线网络认证兼容国内外不同厂商、不同品牌的有线无线网络设备，如华为、新华三、锐捷、Cisco、Aruba、Ruckus、TP-LINK等，以及结合交换机支持胖AP统一接入管理
            </p>
            <button type="button" @click="trial">申请试用</button>
          </div>
          <div class="right">
            <img src="@/assets/img/pc/enterprise/pro_detailed03.png" />
          </div>
        </div>
        <div class="pro_detailed even">
          <div class="left">
            <img src="@/assets/img/pc/enterprise/pro_detailed04.png" />
          </div>
          <div class="right">
            <h3>旁挂接入，降低维护成本</h3>
            <p>
              旁挂接入网络，不影响基础网络架构。其他辅助设备通过白名单方式访问网络。基于核心交换机，同时支持任意品牌胖AP零成本接入。
            </p>
            <button type="button" @click="trial">申请试用</button>
          </div>
        </div>
      </div>

      <!-- <FootMain height="7.105263rem"></FootMain> -->
      <FootMain></FootMain>
    </div>
  </div>
</template>

<script>
import FootMain from "@/components/pc/commom/footmain/index.vue";
export default {
  components: {
    FootMain,
  },
  methods: {
    trial() {
      this.$router.push("/trial");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/pc/enterprise/index.scss";
</style>
